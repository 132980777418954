<template>
  <div>
    <page-component
      pageTitle="Recipes & Ingredients"
      :headers="inventoryHeaders"
      :items="filteredItems"
      :loadingData="!loaded"
      :buttons="[
        {
          text: '+ Add Ingredient',
          to: { params: { form: 'AddEditForm' }, query: { type: 'ingredient' } },
        },
        {
          text: '+ Add Recipe',
          to: { params: { form: 'AddEditForm' }, query: { type: 'recipe' } },
        },
      ]"
    >
      <template v-slot:filters[0]>
        <custom-select
          v-model="selectedTypes"
          :items="types"
          label="Types"
          multiple
        />
      </template>
      <template v-slot:filters[1]>
        <custom-select
          v-model="selectedStorages"
          :items="storages"
          label="Storages"
          item-text="name"
          item-value="id"
          multiple
        />
      </template>
      <template v-slot:[`item.measurement`]="props">
        <div v-if="props.item.measurement">
          {{ getMeasurementForDisplay(props.item.measurement) }}
        </div>
      </template>
      <template v-slot:[`item.storage`]="props">
        {{ $store.getters.getStorageName(props.item.storage) }}
      </template>
      <template v-slot:[`item.supplierItem`]="props">
        {{ getAssociatedSupplierItemName(props.item.itemID) }}
      </template>
      <template v-slot:[`item.isRecipe`]="props">
        {{ (props.item.isRecipe && "Recipe") || "Ingredient" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="report" @click="reportItem(item)" />
        <nxg-action-btn type="edit" @click="editItem(item)" />
        <nxg-action-btn
          v-if="isItemInMenu(item)"
          type="delete"
          @click="showMenuItemErrorDialog(item)"
        />
        <nxg-action-btn
          v-else
          type="delete"
          item-type="INVENTORY_ITEM"
          :item="item"
        />
      </template>
    </page-component>

    <v-dialog
      v-model="showMenuItemError"
      persistent
      width="1000"
      @keydown.esc="showMenuItemError = false"
    >
      <v-card class="pt-2 pb-3">
        <v-card-title>
          <h3>Cannot delete item</h3>
        </v-card-title>
        <v-card-text>
          <p class="mt-4">
            Before deleting {{ selectedItem ? selectedItem.name : "this item" }}, you
            will need to remove it from the following menu items where it's used:
          </p>
          <ul>
            <li
              v-for="selectedItemMenuItem in selectedItemMenuItems"
              :key="selectedItemMenuItem.id"
            >
              <a
                @click="
                  $router.push({
                    name: 'Menu',
                    params: {
                      tab: 'items',
                      form: 'AddEditForm',
                      id: selectedItemMenuItem.id,
                    },
                  })
                "
              >
                {{ selectedItemMenuItem.name }}
              </a>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="showMenuItemError = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <add-edit-form />
    <report-dialog v-if="selectedItem" :menuItems="selectedItemMenuItems" />
    <edit-bulk
      v-if="false"
      :visible="showEditBulk"
      :selectedItems="selectedItems"
      @close="showEditBulk = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import AddEditForm from "./AddEditForm.vue"
import ReportDialog from "../../shared/dialogs/ReportDialog.vue"
import EditBulk from "./EditBulk.vue"
import CustomSelect from "@/AuthenticatedContent/shared/CustomSelect.vue"
import PageComponent from "../../shared/PageComponent.vue"
import topLevelMixins from "../../mixins.js"

export default {
  name: "inventory-items",
  components: { EditBulk, PageComponent, AddEditForm, ReportDialog, CustomSelect },
  mixins: [topLevelMixins],
  data() {
    return {
      selectedTypes: ["recipe", "ingredient"],
      selectedStorages: [],
      types: [
        {
          text: "Recipe",
          value: "recipe",
        },
        {
          text: "Ingredient",
          value: "ingredient",
        },
      ],
      showEditBulk: false,
      showMenuItemError: false,
      selectedItem: null,
      selectedItems: [],
      menuItemsWithItem: {},
      inventoryHeaders: [
        {
          text: "",
          sortable: false,
          class: "header-style",
        },
        { text: "Item ID", value: "itemID" },
        { text: "Name", value: "name" },
        { text: "Remaining Stock", value: "measurement" },
        { text: "Type", value: "isRecipe" },
        { text: "Storage", value: "storage" },
        { text: "Associated Supplier Item", value: "supplierItem" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      loaded: false,
    }
  },
  computed: {
    ...mapGetters(["getUnitName"]),
    ...mapState(["storages", "supplierItems", "items", "menuItems"]),
    selectedItemMenuItems() {
      if (this.selectedItem) {
        return this.menuItemsWithItem[this.selectedItem.itemID]
      } else {
        return []
      }
    },
    filteredItems() {
      return this.items
        .filter(item => {
          if (!this.selectedTypes.includes("recipe") && item.isRecipe) {
            return false
          }
          if (!this.selectedTypes.includes("ingredient") && !item.isRecipe) {
            return false
          }
          return true
        })
        .filter(item => {
          return (
            this.selectedStorages.includes(item.storage) ||
            !this.storages.includes(item.storage) // in case the storage was deleted
          )
        })
    },
  },
  async mounted() {
    this.menuItems.forEach(menuItem => {
      menuItem.ingredients?.forEach(ingredient => {
        if (!this.menuItemsWithItem.hasOwnProperty(ingredient.itemID)) {
          this.menuItemsWithItem[ingredient.itemID] = []
        }
        this.menuItemsWithItem[ingredient.itemID].push(menuItem)
      })
    })

    this.selectedStorages = this.storages.map(storage => storage.id)

    this.loaded = true
  },
  methods: {
    async editItem(item) {
      await this.$store.commit("setItemToEdit", item)
      this.$router.push({ params: { form: "AddEditForm", id: item.itemID } })
    },
    async reportItem(item) {
      this.selectedItem = item
      await this.$store.commit("setItemToReport", item)
      this.$router.push({ params: { form: "ReportDialog", id: item.itemID } })
    },
    showMenuItemErrorDialog(item) {
      this.selectedItem = item
      this.showMenuItemError = true
    },
    isItemInMenu(item) {
      return (
        this.menuItemsWithItem[item.itemID] !== undefined &&
        this.menuItemsWithItem[item.itemID].length
      )
    },
    getAssociatedSupplierItem(itemID) {
      if (!itemID) return
      return this.supplierItems.find(
        supplierItem => supplierItem.linkedItemID === itemID
      )
    },
    getAssociatedSupplierItemName(itemID) {
      const supplierItem = this.getAssociatedSupplierItem(itemID)
      return supplierItem ? supplierItem.name : "-"
    },
    itemUnit(item) {
      this.getUnitName(item.measurement.preferredDisplayUnitId, true)
    },
  },
}
</script>
